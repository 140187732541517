
import { createRoot } from "react-dom/client"
import { Suspense } from "react"
import { App } from "./App"
import "./styles.css"
import MegaScroll from "react-mega-scroll";
import Horse from './components/Horse';
import GithubIcon from "./components/GithubIcon";
import XIcon from './components/XIcon';
import LinkWithSecurity from './components/LinkWithSecurity';

const styles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "60px",
  textAlign: "center",
  color: "white",
};


const mobileMQ = `@media (max-width: 768px)`;



createRoot(document.getElementById("root")).render(
  <MegaScroll>
    <div
    ><Suspense fallback={null}>
        <App />
      </Suspense>
    </div>
    <div style={{ ...styles, background: "#FBFBFB" }}>
      <div className="FooterMainFooter" style={{ width: '100vw', height: 392.16, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex' }}>
        <div className="DivFooterWidgets" style={{
          width: '100vw', height: 338.36, paddingTop: 60, paddingBottom: 60,
          paddingLeft: 40,
          paddigRight: 40,
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 60,
          display: 'inline-flex'
        }}>
          <div className="P" style={{ width: 320.39, height: 142.78, paddingTop: 1, paddingBottom: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex' }}>
            <div className="aa" style={{
              width: 266.50, height: 138.98, display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column'
            }}><span style={{
              color: '#000000',
              fontSize: 12, fontWeight: '700', wordWrap: 'break-word', textAlign: 'left', marginBottom: 3
            }}>Administrative Address<br /></span><span style={{ color: '#000000', fontSize: 12, fontWeight: '500', wordWrap: 'break-word', textAlign: 'left' }}>Qalqi Innovation Labs Private Limited<br />7-13-7, 6th Lane, Vallurivari thota, Arundelpet,<br />Guntur, Andhra Pradesh, India 522002<br />Ph: +91863 2268325</span></div>
          </div>
          <div className="registeredBlock" style={{
            width: 320.39, height: 214.17, paddingTop: 1, paddingBottom: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex',
          }}>
            <div className="rrrr" style={{
              width: 272.11, height: 210.38,
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column'
            }}><span style={{
              color: '#000000', fontSize: 12, fontWeight: '700', wordWrap: 'break-word',
              textAlign: 'left', marginBottom: 3
            }}>Registered Address<br /></span><span style={{ color: '#000000', fontSize: 12, fontWeight: '500', wordWrap: 'break-word', textAlign: 'left' }}>No. 90, 27th Main, Sector-1, HSR Layout, Bangalore, Karnataka, India 560102<br />Ph: +9180 42134897<br />Qalqi Inc., a Delaware corporation<br />1 Thrush Dr, East Brunswick, NJ USA 08816<br />Ph: +14142074535</span></div>
          </div>
          <div className="DivFooterWidget" style={{ width: 320.39, height: 130.78, justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex' }}>
            <div className="P" style={{ width: 243, height: 131, paddingTop: 1, paddingBottom: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', display: 'inline-flex' }}>
              <span style={{
                color: '#000000', fontSize: 12, fontWeight: '700', wordWrap: 'break-word',
                textAlign: 'left',
                marginBottom: 3
              }}>Company Details<br /></span>
              <div className="cdd" style={{ width: 205, height: 129, color: '#000000', fontSize: 12, fontWeight: '500', wordWrap: 'break-word', textAlign: 'left' }}>CIN: U51909KA2012PTC061977<br />GST: 37AAACQ2689N1ZR<br />EIN: 61-1775427<br />Import Export Code: 0711036136<br />
                UPI: qalqi@rbl<br /></div>
            </div>
            <Horse />
          </div>
        </div>
        <div className="DivFooterBottom" style={{ width: '100vw', height: 53.80, paddingTop: 5, paddingBottom: 5, flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', display: 'flex' }}>
          <div className="DivContainer" style={{
            width: '100vw',
            paddingLeft: 40,
            paddingRight: 40,
            justifyContent: 'space-between',
            height: 33.80, alignItems: 'flex-start', display: 'inline-flex'
          }}>
            <div className="DivFooterInfo" style={{ height: 34, paddingTop: 1, paddingBottom: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
              <div className="qalqiInc" style={{ height: 20, color: '#C4C4C4', fontSize: 12, fontWeight: '500', wordWrap: 'break-word' }}>© 2024 Qalqi Innovation Labs Private Limited & Qalqi Inc, All Rights Reserved</div>
            </div>
            <div className="UlEtSocialIcons" style={{ height: 35, paddingTop: 10, paddingBottom: 10, justifyContent: 'flex-end', alignItems: 'center', gap: 10, display: 'flex' }}>
              <div className="Frame3656" style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'flex' }}>
                <LinkWithSecurity href="https://x.com/qalqi"><XIcon /></LinkWithSecurity>
                <LinkWithSecurity href="https://github.com/qalqi"><GithubIcon /></LinkWithSecurity>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div style={{ ...styles, background: "#333" }}>Keyboard Support</div>
      <div style={{ ...styles, background: "#222" }}>State Listeners</div>
    <div style={{ ...styles, background: "#111" }}>Free Forever</div> */}
  </MegaScroll>
  ,
)
