import React, { useState } from 'react';

const LinkWithSecurity = ({ children, href }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    // Reset the state after 2 seconds (adjust as needed)
    setTimeout(() => {
      setIsClicked(false);
    }, 1000);
  };

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      onClick={handleClick}
      style={{ opacity: isClicked ? 0.5 : 1 }}
    >
      {children}
    </a>
  );
};

export default LinkWithSecurity;