const QalqiLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={83}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#464344"
      d="M8.75 17.583c-1.458 0-2.732-.246-3.822-.737-1.09-.506-2.004-1.174-2.74-2.003A8.866 8.866 0 0 1 .552 12.01 9.55 9.55 0 0 1 0 8.81c0-1.09.184-2.15.553-3.178a8.599 8.599 0 0 1 1.635-2.809c.736-.829 1.65-1.489 2.74-1.98C6.018.336 7.292.083 8.75.083c1.443 0 2.71.253 3.8.76 1.09.491 1.995 1.151 2.716 1.98a8.394 8.394 0 0 1 1.658 2.81 9.352 9.352 0 0 1 .553 3.177c0 1.06-.177 2.103-.53 3.132a8.42 8.42 0 0 1-1.52 2.74l2.326 2.67h-4.098l-.645-.736a8.632 8.632 0 0 1-1.98.714 9.782 9.782 0 0 1-2.28.253Zm0-3.57c.599 0 1.159-.099 1.68-.298l-2.578-2.902 2.487-2.003 2.371 2.74c.415-.829.622-1.742.622-2.74 0-.86-.176-1.68-.53-2.464A4.607 4.607 0 0 0 11.26 4.39c-.676-.507-1.512-.76-2.51-.76-1.013 0-1.865.253-2.556.76a4.676 4.676 0 0 0-1.52 1.957 6.156 6.156 0 0 0-.506 2.464c0 .875.169 1.712.506 2.51a4.705 4.705 0 0 0 1.52 1.934c.69.507 1.543.76 2.556.76ZM18.838 17.353 24.572.313h5.342l5.734 17.04H31.48l-.99-3.109h-6.494l-.99 3.109h-4.168Zm6.264-6.517h4.305l-2.164-6.7-2.141 6.7ZM37.703 17.353V.313h3.984V13.9h8.197v3.454h-12.18Z"
    />
    <path
      fill="#C82128"
      d="M59.885 17.583c-1.459 0-2.733-.246-3.823-.737-1.09-.506-2.003-1.174-2.74-2.003a8.867 8.867 0 0 1-1.635-2.832 9.55 9.55 0 0 1-.552-3.201c0-1.09.184-2.15.552-3.178a8.6 8.6 0 0 1 1.635-2.809c.737-.829 1.65-1.489 2.74-1.98 1.09-.507 2.364-.76 3.823-.76 1.443 0 2.709.253 3.799.76 1.09.491 1.996 1.151 2.717 1.98a8.394 8.394 0 0 1 1.658 2.81 9.352 9.352 0 0 1 .553 3.177c0 1.06-.177 2.103-.53 3.132a8.42 8.42 0 0 1-1.52 2.74l2.326 2.67h-4.099l-.645-.736a8.632 8.632 0 0 1-1.98.714 9.782 9.782 0 0 1-2.28.253Zm0-3.57c.598 0 1.159-.099 1.68-.298l-2.578-2.902 2.486-2.003 2.372 2.74c.415-.829.622-1.742.622-2.74 0-.86-.177-1.68-.53-2.464a4.607 4.607 0 0 0-1.542-1.957c-.676-.507-1.513-.76-2.51-.76-1.014 0-1.865.253-2.556.76a4.677 4.677 0 0 0-1.52 1.957 6.156 6.156 0 0 0-.507 2.464c0 .875.17 1.712.507 2.51a4.706 4.706 0 0 0 1.52 1.934c.69.507 1.542.76 2.556.76Z"
    />
    <path
      fill="#464344"
      d="M70.695.32h11.746v3.456H78.58v10.098h3.862v3.455H78.58v.031h-3.984v-.031h-3.9v-3.455h3.9V3.776h-3.9V.32Z"
    />
    <path
      fill="#811418"
      d="m61.47 8.81-2.497 1.997 5.816 6.546h4.099L61.469 8.81Z"
    />
  </svg>)

export function Underlay() {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        padding: 40,
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        pointerEvents: "none",
        zIndex: 90
      }}>
      <div style={{ width: "100%", padding: 0, display: "inline-flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        {/* <p
          style={{
            fontFamily: "'Poppins', sans-serif",
            flex: "1 1 0%",
            height: 30,
            fontSize: 30,
            fontWeight: "700",
            lineHeight: "30px",
            color: "#C82128",
            letterSpacing: -2,
          }}>
          qalqi.com
        </p> */}
        <a href="/">
          <QalqiLogo />
        </a>
        <div style={{ flex: "1 1 0%", display: "flex", gap: "2em" }}></div>
        <p style={{ flex: "1 1 0%", display: "flex", gap: 18, fontSize: 12, lineHeight: "1em", textAlign: "right", color: "black", alignItems: "center", justifyContent: "flex-end" }}>
          <a target="_blank" rel="noopener noreferrer" style={{ display: 'block', cursor: "pointer", color: "#C82128" }} href="http://qrd.at/1234">1234</a>
          <a target="_blank" rel="noopener noreferrer" style={{display: 'block', cursor: "pointer", color: "#C82128" }} href="http://qrd.at/family">Family Feels</a>
        </p>
      </div>
      <div style={{ height: 60 }} />
      <div style={{ width: "100%", padding: 0, display: "inline-flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center" }}>
        <p style={{ flex: "1 1 0%", height: "100%", fontSize: 12, lineHeight: "1.5em", color: "black" }}>
          <b>You are the center of universe</b>
          <br />
          A Universal Deity
          <br />
          <b>—</b>
        </p>
        <div style={{ width: 10 }} />
        <p
          style={{
            fontSize: 12,
            fontWeight: "700",
            lineHeight: "100%",
            textAlign: "right",
            color: "black",
            whiteSpace: "nowrap",
            writingMode: "vertical-rl"
          }}>
          あなたは宇宙の中心です
        </p>
      </div>
      <div style={{ height: 10 }} />
      <div
        className="full"
        style={{
          fontFamily: "'Poppins', sans-serif",
          width: "100%",
          flex: "1 1 0%",
          padding: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "center",
        }}>
        <div style={{ width: 10 }} />
        <p style={{ flex: "1 1 0%", fontSize: 45, lineHeight: "100%", textAlign: "right", color: "black", margin: 0, letterSpacing: -10 }}>1</p>
      </div>
      <div style={{ height: 60 }} />
      <div
        style={{
          pointerEvents: "all",
          pointer: "auto",
          width: "100%",
          padding: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "center",
        }}>
        <p className="full" style={{ whiteSpace: "nowrap", flex: "1 1 0%", fontSize: 12, lineHeight: "1.5em", color: "black" }}>
          <b>మీరు విశ్వానికి కేంద్ర బిందువు</b>
          <br />
          Ты центр вселенной
        </p>
        <div style={{ width: 10 }} />
        <p
          className="full"
          style={{
            fontFamily: "'Poppins', sans-serif",
            flex: "1 1 0%",
            fontSize: 16,
            fontWeight: "700",
            lineHeight: "1em",
            textAlign: "center",
            color: "black",
            letterSpacing: -0.5,
            whiteSpace: "nowrap",
          }}>
          你是宇宙的中心
        </p>
        <div style={{ width: 10 }} />
        <p className="full" style={{ flex: "1 1 0%", fontSize: 12, lineHeight: "1em", textAlign: "right", color: "black" }}></p>
      </div>
    </div>
  )
}

export function Overlay() {
  return (
    <div style={{
      position: "absolute", top: 40, right: 40, zIndex: 99,
      display: "none"
    }}>
      <p style={{ flex: "1 1 0%", gap: 18, fontSize: 12, lineHeight: "1em", textAlign: "right", color: "black" }}>
        <a target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer", color: "#C82128" }} href="http://qrd.at/1234">1234</a>
        <a target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer", color: "#C82128" }} href="http://qrd.at/family">Family Feels</a>
      </p>
    </div>
  )
}
